<template>
  <div class="citysContent">
    <div class="title">所属省市</div>
    <div class="pie">
      <div id="citysChart"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      citys: [],
      maxValue: 0
    };
  },

  mounted() {
    this.myChart = this.$echarts.init(document.getElementById("citysChart"));
    this.getData();
  },

  methods: {
    // 获取所属省市数据
    getData() {
      this.$http.get("kanban/province/count").then(res => {
        this.citys = res.data;
        this.maxValue = Math.max(...this.citys.map(item => item.value));
        this.initHandle();
        if (this.citys.length >= 10) {
          setInterval(() => {
            const temp = this.citys.splice(0, 1);
            this.citys = this.citys.concat(temp);
            this.initHandle();
          }, 2000);
        }
      });
    },

    initHandle() {
      const option = {
        dataset: {
          dimensions: ["name", "value"],
          source: this.citys.slice(0, 10)
        },
        grid: {
          top: "20%",
          bottom: 16,
          left: 24,
          right: 24,
          containLabel: true
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#1A1A1A",
            fontSize: 14,
            lineHeight: 20
          }
        },
        yAxis: {
          show: false
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            itemStyle: {
              borderRadius: 4,
              color: (params) => {
                if (params.data.value == this.maxValue) {
                  return "#E60012";
                } else {
                  return "#7D7D7D";
                }
              }
            },
            label: {
              show: true,
              position: "top",
              color: "#1A1A1A",
              fontSize: 16,
              fontWeight: "bold",
            }
          }
        ]
      };
      this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.citysContent {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    padding-left: 16px;
    color: #4D4D4D;
    padding-top: 16px;
  }

  .pie {
    flex: 1;

    #citysChart {
      height: 90%;
    }
  }
}
</style>