<template>
  <div class="statusCheck">
    <div class="title">物联电梯状态</div>
    <div class="pie">
      <div id="statusChart"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      statusData: [],
    };
  },

  mounted() {
    this.myChart = this.$echarts.init(document.getElementById("statusChart"));
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("kanban/count/status").then(res => {
        this.statusData = res.data;
        this.total = this.statusData.reduce((accumulator, currentItem) => accumulator + currentItem.value, 0) + "";
        this.initHandle();
      });
    },

    initHandle() {
      const option = {
        tooltip: {
          trigger: "item"
        },
        // 放在右侧
        legend: {
          orient: "vertical",
          top: "center",
          left: "50%",
          formatter: (name) => {
            const value = this.statusData.find(item => item.name === name).value;
            return name + "{a|" + value + "}";
          },
          textStyle: {
            lineHeight: 40,
            rich: {
              a: {
                color: "#1A1A1A",
                fontSize: 22,
                fontWeight: "bold",
                padding: [0, 0, 0, 16]
              }
            }
          }
        },
        // 配置饼图颜色
        color: ["#E60012", "#7D7D7D", "#C2C9CC"],
        series: [
          {
            name: "物联电梯状态",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["120px", "50%"],
            data: this.statusData,
            label: {
              show: true,
              position: "center",
              color: "#1A1A1A",
              fontSize: 22,
              fontWeight: "bold",
              formatter: this.total,
            }
          }
        ]
      };
      this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.statusCheck {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    line-height: 56px;
    background-color: #4D4D4D;
    border-radius: 12px 12px 0 0;
    font-size: 20px;
    padding-left: 16px;
    color: #fff;
  }

  .pie {
    flex: 1;

    #statusChart {
      height: 90%;
    }
  }
}
</style>