<template>
  <div id="chart" style="width: 100%; height: 90%;"></div>
</template>

<script>

export default {
  data() {
    return {
      myChart: null,
      data: [],
      lines: [],
      geoCoordMap: {
        北京市: [116.46, 39.92],
        天津市: [117.2, 39.13],
        河北省: [114.48, 38.03],
        山西省: [112.53, 37.87],
        内蒙古自治区: [111.65, 40.82],
        辽宁省: [123.38, 41.8],
        吉林省: [125.35, 43.88],
        黑龙江省: [126.63, 45.75],
        上海市: [121.48, 31.22],
        江苏省: [118.78, 32.04],
        浙江省: [120.19, 30.26],
        安徽省: [117.27, 31.86],
        福建省: [119.3, 26.08],
        江西省: [115.89, 28.68],
        山东省: [117, 36.65],
        河南省: [113.65, 34.76],
        湖北省: [114.31, 30.52],
        湖南省: [113, 28.21],
        广东省: [113.23, 23.16],
        广西壮族自治区: [108.33, 22.84],
        海南省: [110.35, 20.02],
        重庆市: [106.54, 29.59],
        四川省: [104.06, 30.67],
        贵州省: [106.71, 26.57],
        云南省: [102.73, 25.04],
        西藏自治区: [91.11, 29.97],
        陕西省: [108.95, 34.27],
        甘肃省: [103.73, 36.03],
        青海省: [101.74, 36.56],
        宁夏回族自治区: [106.27, 38.47],
        新疆维吾尔自治区: [87.68, 43.77],
        台湾省: [121.58, 24.99],
        香港特别行政区: [116.42, 23.35],
        澳门特别行政区: [113.58, 22.15],
      }
    };
  },

  mounted() {
    this.myChart = this.$echarts.init(document.getElementById("chart"));
    this.$echarts.registerMap("china", require("@/assets/json/china.json"));
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("kanban/province/count").then(res => {
        this.data = res.data;
        this.data.forEach(item => {
          const result = this.geoCoordMap[item.name];
          this.lines.push({
            coords: [[120.19, 30.26], result]
          });
        });
        const result = this.data.filter(item => item.name === "浙江省");
        if (!result.length) {
          this.data.push({
            name: "浙江省",
            value: 100000
          });
        }
        this.initChart();
      });
    },

    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            lat: geoCoord[data[i].name],
            value: geoCoord.concat(data[i].value)
          });
        }
      }
      return res;
    },

    initChart() {
      const option = {
        tooltip: {
          trigger: "item"
        },
        geo: {
          map: "china",
          zoom: 1.2,
          itemStyle: {
            areaColor: "#fff",
            borderColor: "#EBEBEB",
            shadowBlur: 2, // 阴影的模糊大小
            shadowColor: "rgba(0, 0, 0, 0.1)", // 阴影的颜色
          },
          emphasis: {
            disabled: true,
          }
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.convertData(this.data),
            symbolSize: (val) => {
              if (val[2] > 10000) {
                return 20;
              } else if (val[2] > 5000) {
                return 15;
              } else if (val[2] > 1000) {
                return 10;
              } else {
                return 8;
              }
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke"
            },
            hoverAnimation: true,
            encode: {
              value: 2
            },
            itemStyle: {
              color: (data) => {
                if (data.data.name === "浙江省") {
                  return "red";
                } else {
                  return "#7D7D7D";
                }
              },
              shadowBlur: 10,
              shadowColor: "#333"
            },
            zlevel: 1,
            emphasis: {
              scale: true,
            }
          },
          {
            type: "lines",
            coordinateSystem: "geo",
            lineStyle: {
              width: 1,
              color: "#ccc",
              curveness: 0.2 // 曲线的曲率
            },
            effect: {
              show: true,
              scaleSize: 3,
              color: "#fff",
              shadowBlur: 10
            },
            data: this.lines
            // data: [
            //   {
            //     coords: [
            //       [120.43, 30.89],
            //       [87.68, 43.77],
            //     ],
            //   },
            //   {
            //     coords: [
            //       [120.43, 30.89],
            //       [103.73, 36.03],
            //     ],
            //   },
            //   {
            //     coords: [
            //       [120.43, 30.89],
            //       [114.31, 30.52],
            //     ],
            //   }
            // ]
          }
        ]
      };
      this.myChart.setOption(option);
    },
  },
};
</script>
