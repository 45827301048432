<template>
  <div class="faultRecordsBox">
    <div class="title">实时故障</div>
    <div class="faultRecords">
      <vue-seamless-scroll :data="faults" class="seamless" :class-option="defineScroll">
        <div class="faultItem" v-for="(item, index) in faults" :key="index">
          <div class="faultTop">
            <div class="address">{{ item.elevatorName }}</div>
            <div class="time">{{ item.faultTime }}</div>
          </div>
          <div class="faultBottom">
            {{ item.faultDesc }}
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import VueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: { VueSeamlessScroll },
  data() {
    return {
      faults: []
    };
  },

  computed: {
    defineScroll() {
      return {
        step: 0.5
      };
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("kanban/fault").then(res => {
        this.faults = res.data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.faultRecordsBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title {
    font-size: 20px;
    color: #4D4D4D;
    padding: 16px;
  }

  .faultRecords {
    flex: 1;
    padding: 0 16px 0 16px;
    overflow: hidden;

    .faultItem {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 16px 12px;
      margin-bottom: 16px;

      .faultTop {
        display: flex;
        align-items: center;

        .address {
          flex: 1;
          color: #1A1A1A;
          font-weight: bold;
        }

        .time {
          color: #808080;
          font-size: 12px;
        }
      }

      .faultBottom {
        color: #E60012;
        margin-top: 16px;
      }
    }
  }
}
</style>