<template>
  <div class="proportion">
    <div class="title">系统占比</div>
    <div class="pie">
      <div id="proportionChart"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      proportions: [],
    };
  },

  mounted() {
    this.myChart = this.$echarts.init(document.getElementById("proportionChart"));
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("kanban/system/count").then(res => {
        // console.log("系统占比", res);
        this.proportions = res.data.sort((a, b) => b.value - a.value);
        this.initHandle();
      });
    },

    initHandle() {
      const option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "10%"
        },
        color: ["#E60012", "#7D7D7D", "#92979A", "#AAAFB2", "#C2C9CC", "#DDE6E9"],
        series: [
          {
            name: "系统占比",
            type: "pie",
            radius: ["45%", "80%"],
            center: ["50%", "60%"],
            startAngle: 180,
            endAngle: 360,
            label: {
              show: true,
              fontSize: 16,
              fontWeight: "bold",
              color: "#1A1A1A",
              formatter: "{d}%"
            },
            data: this.proportions
          }
        ]
      };
      this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.proportion {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    padding-left: 16px;
    color: #4D4D4D;
  }

  .pie {
    flex: 1;

    #proportionChart {
      height: 90%;
    }
  }
}
</style>