<template>
  <div class="board">
    <img src="../../assets/topline@2x.png" style="width: 100%;"/>
    <div class="content">
      <el-row>
        <el-col :span="7">
          <div class="left">
            <!-- 物联电梯状态 -->
            <div class="status block">
              <StatusChart></StatusChart>
            </div>
            <!-- 系统占比 -->
            <div class="proportion block">
              <ProportionChart></ProportionChart>
            </div>
            <!-- 抽查合格率 -->
            <div class="yield block">
              <CheckYield></CheckYield>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="middle">
            <!-- 统计 -->
            <div class="count">
              <Count></Count>
            </div>
            <!-- 地图 -->
            <div class="map">
              <Map></Map>
            </div>
            <!-- 所属城市 -->
            <div class="citys">
              <CitysChart></CitysChart>
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="right">
            <!-- 实时故障 -->
            <div class="faults">
              <FaultRecords></FaultRecords>
            </div>
            <!-- 故障Top5 -->
            <div class="faultRank block">
              <FaultRank></FaultRank>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import StatusChart from "@/views/KanBan/StatusChart";
import ProportionChart from "@/views/KanBan/ProportionChart";
import CheckYield from "@/views/KanBan/CheckYield";
import CitysChart from "@/views/KanBan/CitysChart";
import FaultRank from "@/views/KanBan/FaultRank";
import FaultRecords from "@/views/KanBan/FaultRecords";
import Count from "@/views/KanBan/Count";
import Map from "@/views/KanBan/Map";

export default {
  components: { StatusChart, ProportionChart, CheckYield, CitysChart, FaultRank, FaultRecords, Count, Map },
};
</script>

<style lang="scss" scoped>
.board {
  height: 100%;
  background-color: #EBEBEB;
  display: flex;
  flex-direction: column;
  min-height: 800px;
  min-width: 1600px;

  // 公共样式
  .block {
    background-color: #fff;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 12px 12px 12px 12px;
  }

  .content {
    flex: 1;
    padding: 0 32px 16px 32px;
    min-height: 0;

    .el-row,
    .el-col,
    .left,
    .middle,
    .right {
      height: 100%;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // 物联电梯状态
      .status {
        height: 32%;
      }

      // 系统占比
      .proportion {
        flex: 1;
        margin: 16px 0;
        min-height: 0;
      }

      // 抽查合格率
      .yield {
        height: 32%;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .middle {
      padding: 0 16px;
      display: flex;
      flex-direction: column;

      // 统计
      .count {
        margin-top: 10px;
      }

      // 地图
      .map {
        flex: 1;
      }

      // 所属省市
      .citys {
        height: 32%;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      // 实时故障
      .faults {
        flex: 1;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin-bottom: 16px;
        min-height: 0;
      }

      // 故障Top5
      .faultRank {
        height: 32%;
      }
    }
  }
}
</style>