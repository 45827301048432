<template>
  <div class="count">
    <el-row :gutter="5">
      <el-col :span="6">
        <div class="grid-content">
          <img src="../../assets/sbzs@2x.png" style="width: 80px;height: 80px;"/>
          <div>
            <div>设备总数</div>
            <div class="number">{{ sbzs }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <img src="../../assets/rwzs@2x.png" style="width: 80px;height: 80px;"/>
          <div>
            <div>入网总数</div>
            <div class="number">{{ rwzs }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <img src="../../assets/zxzs@2x.png" style="width: 80px;height: 80px;"/>
          <div>
            <div>在线总数</div>
            <div class="number">{{ zxzs }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <img src="../../assets/dqjx@2x.png" style="width: 80px;height: 80px;"/>
          <div>
            <div>当前急修</div>
            <div class="number">{{ dqjx }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sbzs: 0,
      rwzs: 0,
      zxzs: 0,
      dqjx: 0
    };
  },

  mounted() {
    this.getSBZS();
    this.getRWZS();
    this.getZXZS();
    this.getDQJX();
  },

  methods: {
    getSBZS() {
      this.$http.get("kanban/elevator/count").then(res => {
        this.sbzs = res.data;
      });
    },

    getRWZS() {
      this.$http.get("kanban/inNet/count").then(res => {
        this.rwzs = res.data;
      });
    },

    getZXZS() {
      this.$http.get("kanban/online/count").then(res => {
        this.zxzs = res.data;
      });
    },

    getDQJX() {
      this.$http.get("kanban/workOrder/count").then(res => {
        this.dqjx = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  align-items: center;
  color: #4D4D4D;
  font-weight: bold;

  .number {
    font-family: "electronicFont";
    font-size: 40px;
    color: #1A1A1A;
    font-weight: normal;
  }
}
</style>