<template>
  <div class="yieldCheck">
    <div class="title">抽查合格率</div>
    <div class="yieldContent">
      <el-row :gutter="16">
        <el-col :span="8" v-for="(item, index) in data" :key="index">
          <div class="grid-content">
            <div class="number">
              <span>{{ item.value }}</span>
              <span class="char">%</span>
            </div>
            <div class="checkName">{{ item.name }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: []
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("kanban/safeCheck/count").then(res => {
        this.data = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.yieldCheck {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    padding-left: 16px;
    color: #4D4D4D;
    padding-top: 16px;
  }

  .yieldContent {
    flex: 1;
    padding: 0 16px;

    .grid-content {
      padding: 10px 0;
      background-color: #fff;
      border-radius: 12px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .number {
        font-family: "electronicFont";
        font-size: 30px;
        color: #E60012;

        .char {
          font-size: 16px;
        }
      }

      .checkName {
        color: #1A1A1A;
        font-weight: bold;
      }
    }
  }
}
</style>